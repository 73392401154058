import errors from "@koble/errors";
import { MessageInstance } from "antd/lib/message/interface";

// eslint-disable-next-line no-undef
export interface FetchHandlerArgs extends RequestInit {
  successMessage?: string;
  errorMessage?: string;
  messageApi?: MessageInstance;
  ignoredErrorCodes?: string[];
}

const handleFetch = async (url: string, args: FetchHandlerArgs) => {
  const { successMessage, messageApi, errorMessage } = args;
  let logged = false;
  let statusCode = null;

  try {
    const response = await fetch(url, args);
    statusCode = response.status;

    if (!response.ok) {
      const res = await response.json();
      const code = res.code;
      const message = res.message;
      const spanishError = errors[code as string]?.spanish;

      if (messageApi && errorMessage && spanishError) {
        messageApi.error(`${errorMessage} ${spanishError} (${statusCode})`);
        logged = true;
      } else if (messageApi && errorMessage) {
        messageApi.error(`${errorMessage} (${statusCode})`);
        logged = true;
      }

      throw new Error(`${code}: ${message}`);
    } else if (messageApi && successMessage) {
      messageApi.success(successMessage);
    }

    return await response.json();
  } catch (e: any) {
    if (messageApi && errorMessage && !logged) {
      messageApi.error(`${errorMessage} (${statusCode})`);
    }

    throw e;
  }
};

export default handleFetch;
