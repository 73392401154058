import { IdentityClaims } from "@koble/utils/src/UserSessionManager";
import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  accessToken,
  identityServerUrl,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  accessToken: string;
  identityServerUrl: string;
}): Promise<IdentityClaims> => {
  const urlParams = new URLSearchParams({
    accessToken,
  }).toString();

  const url = `${identityServerUrl}/connect/userinfo?${urlParams}`;

  return handleFetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: "POST",
    messageApi,
    errorMessage,
    successMessage,
  });
};
