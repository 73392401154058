/* eslint-disable react/prop-types */

"use client";

import { useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  ProForm,
  ProFormInstance,
  ProFormText,
} from "@ant-design/pro-components";
import { signInEmail } from "@koble/api/identity";
import { assets } from "@koble/assets/assets";
import { useMessage } from "@koble/hooks";
import { TitleWithDescription } from "@koble/ui/src/CommonText";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Typography } from "antd";
import Cookies from "js-cookie";
import Link from "next/link";
import { useRouter } from "next/navigation";

import config from "@/config";

const { Text } = Typography;

const SsoSignInPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { messageApi } = useMessage();
  const router = useRouter();

  const passwordInput = useRef<any>(null);
  const formRef = useRef<
    ProFormInstance<{
      email: string;
      password: string;
    }>
  >();

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: `${config.NEXT_PUBLIC_IDENTITY_SERVER}/sign-in/google`,
    ux_mode: "redirect",
  });

  return (
    <>
      <div
        style={{
          maxWidth: 400,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TitleWithDescription>Iniciar sesión</TitleWithDescription>
        <ProForm
          layout="vertical"
          style={{
            marginTop: 10,
            width: "100%",
          }}
          formRef={formRef}
          autoFocusFirstInput
          onFinish={async (values) => {
            if (!executeRecaptcha) throw new Error("Recaptcha not initialized");
            const captchaResponse = await executeRecaptcha("login");

            const tokens = await signInEmail({
              username: values.email,
              password: values.password,
              captchaResponse,
              messageApi,
              identityServerUrl: config.NEXT_PUBLIC_IDENTITY_SERVER,
              errorMessage: "Ocurrió un error al iniciar sesión.",
            });

            Cookies.set("accessToken", tokens.accessToken, {
              domain: config.COOKIES_DOMAIN,
              expires: new Date(new Date().getTime() + tokens.expiresIn * 1000),
            });
            Cookies.set("refreshToken", tokens.refreshToken, {
              domain: config.COOKIES_DOMAIN,
              expires: new Date(new Date().getTime() + tokens.expiresIn * 1000),
            });

            router.push("/cb");
          }}
          submitter={{
            render: (props) => {
              return [
                <Button
                  key="save"
                  onClick={() => {
                    props.submit();
                  }}
                  type="primary"
                  loading={
                    props.submitButtonProps && props.submitButtonProps.loading
                  }
                  style={{
                    width: "100%",
                  }}
                >
                  Iniciar sesión
                </Button>,
              ];
            },
          }}
        >
          <ProFormText
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor ingresa tu correo",
              },
              {
                type: "email",
                message: "Por favor ingresa un correo válido",
              },
            ]}
            placeholder="Ingresa tu correo"
            fieldProps={{
              type: "email",
              onKeyPress: (e) => {
                if (e.key === "Enter") {
                  passwordInput.current.focus();
                }
              },
            }}
          />
          <ProFormText.Password
            name="password"
            rules={[
              {
                required: true,
                message: "Por favor ingresa tu contraseña",
              },
            ]}
            placeholder="Ingresa tu contraseña"
            fieldProps={{
              type: "password",
              ref: passwordInput,
              onKeyPress: (e) => {
                if (e.key === "Enter") {
                  formRef.current?.submit();
                }
              },
            }}
          />
          <div
            style={{
              marginTop: 5,
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link
              style={{ fontSize: 12, marginTop: 5 }}
              href="/forgot-password"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </ProForm>
        <Divider>
          <Text style={{ fontSize: 12 }}>¿No tienes una cuenta aún? </Text>{" "}
          <Link style={{ marginLeft: 10, fontSize: 12 }} href="/sign-up">
            Crear cuenta
          </Link>
        </Divider>
        <Button
          type="default"
          style={{
            width: "100%",
            marginBottom: 0,
          }}
          size="large"
          onClick={() => {
            loginWithGoogle();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={assets.GOOGLE_LOGO}
              style={{
                width: 18,
                marginRight: 10,
                imageRendering: "crisp-edges",
              }}
              alt="Sign in with Google"
            />{" "}
            <Text
              style={{
                fontSize: 13,
                marginTop: 2,
              }}
            >
              Iniciar sesión con Google
            </Text>
          </div>
        </Button>
      </div>
    </>
  );
};

export default SsoSignInPage;
