// TODO: Find a way to not use array indexes as keys
/* eslint react/no-array-index-key: 0 */

import { splitIntoNewlines } from "@koble/utils/src/textProcessing";
import { Typography } from "antd";
import { ParagraphProps } from "antd/es/typography/Paragraph";

interface BreakableParagraphProps extends ParagraphProps {
  children: string;
}

const { Paragraph } = Typography;

const BreakableParagraph = ({ children, ...rest }: BreakableParagraphProps) => {
  const lines = splitIntoNewlines(children);

  return (
    <Typography>
      {lines.map((line, index) => {
        const currentLineIsEmpty = lines[index] === "";

        const providedStyle = rest?.style;

        return (
          <div key={`breakable-paragraph-wrapper-${index}`}>
            {!currentLineIsEmpty ? (
              <Paragraph
                {...rest}
                style={{
                  ...providedStyle,
                  marginBottom: 0,
                }}
              >
                {line}
              </Paragraph>
            ) : (
              <br />
            )}
          </div>
        );
      })}
    </Typography>
  );
};

export default BreakableParagraph;
