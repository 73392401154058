import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  email,
  captchaResponse,
  identityServerUrl,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  email: string;
  captchaResponse: string;
  identityServerUrl: string;
}) => {
  const urlParams = new URLSearchParams({
    email,
    captchaResponse,
  }).toString();

  const url = `${identityServerUrl}/sign-up/email/user-recruiter?${urlParams}`;

  return handleFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    messageApi,
    errorMessage,
    successMessage,
  });
};
