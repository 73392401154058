import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  captchaResponse,
  email,
  identityServerUrl,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  email: string;
  captchaResponse: string;
  identityServerUrl: string;
}): Promise<{
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
}> => {
  const urlParams = new URLSearchParams({
    email,
    captchaResponse,
  }).toString();

  const url = `${identityServerUrl}/send-reset-password-email?${urlParams}`;

  return handleFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    messageApi,
    errorMessage,
    successMessage,
  });
};
