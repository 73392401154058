import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  countryCode,
  phoneNumber,
  identityServerUrl,
  captchaResponse,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  countryCode: number;
  phoneNumber: string;
  identityServerUrl: string;
  captchaResponse: string;
}) => {
  const urlParams = new URLSearchParams({
    countryCode: countryCode.toString(),
    phoneNumber,
    captchaResponse,
  }).toString();

  const url = `${identityServerUrl}/send-phone-verification-code?${urlParams}`;

  return handleFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    messageApi,
    errorMessage,
    successMessage,
  });
};
