import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  identityServerUrl,
  phoneNumber,
  phoneCountryCodeId,
  phoneVerificationCode,
  firstName,
  lastName,
  password,
  token,
  referralCode,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  identityServerUrl: string;
  phoneNumber: string;
  phoneCountryCodeId: string;
  phoneVerificationCode: string;
  firstName: string;
  lastName: string;
  password: string;
  token: string;
  referralCode?: string;
}): Promise<{
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
}> => {
  const params: Record<string, string> = {
    phoneNumber,
    phoneCountryCodeId,
    phoneVerificationCode,
    firstName,
    lastName,
    password,
    token,
  };

  if (referralCode) {
    params.referralCode = referralCode;
  }

  // Construct the URL with query parameters
  const urlParams = new URLSearchParams(params).toString();

  const url = `${identityServerUrl}/complete/email/user-student?${urlParams}`;
  return handleFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    messageApi,
    errorMessage,
    successMessage,
  });
};
