import { MessageInstance } from "antd/lib/message/interface";

import handleFetch from "../handlers/handleFetch";

export default async ({
  messageApi,
  errorMessage,
  successMessage,
  accessToken,
  identityServerUrl,
}: {
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  accessToken: string;
  identityServerUrl: string;
}): Promise<{
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
}> => {
  const urlParams = new URLSearchParams({
    accessToken,
  }).toString();

  const url = `${identityServerUrl}/sign-out?${urlParams}`;

  return handleFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    messageApi,
    errorMessage,
    successMessage,
  });
};
